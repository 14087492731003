<template>
  <div class="productdetails">
    <div class="mainContentSection">
      <div class="container">
        <div class="shopTop">
            <div class="row">
                <div class="col-lg-6">
                  <VueSlickCarousel
                    ref="c1"
                    :asNavFor="$refs.c2"
                    :focusOnSelect="true" :arrows="false" :dots="false" :fade="true">
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                   </VueSlickCarousel>
                  <VueSlickCarousel
                    ref="c2"
                    :asNavFor="$refs.c1"
                    :slidesToShow="4"
                    :focusOnSelect="true" class="slicknav">
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                    <div class="item"><img src="../assets/images/productSlider.png" alt=""></div>
                  </VueSlickCarousel>
                </div>
                <div class="col-lg-6">
                    <div class="proDetails">
                        <h2>Naam van het product</h2>
                        <div class="priceDn">€ 55,99</div>
                        <div class="partition"></div>
                        <div class="stock">Op voorraad </div>
                        <div class="shortDes">
                            <p>Gratis bezorging in NL en BE bij bestellingen boven de € 50,- Voor 12.00 uur besteld, vandaag verzonden!</p>
                        </div>
                        <div class="buyToCart"><a href="#" class="btnAf"> Buy Now</a></div>
                        <div class="addToCart"><a href="#" class="btnAf">Add to cart</a></div>
                        <div class="shopBtmWish">
                            <ul>
                                <li><a href="#"><img src="../assets/images/wishlist.svg" alt="">Wishlist</a></li>
                                <li><a href="#"><img src="../assets/images/share.svg" alt="">Share</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section4">
            <div class="relaxBg customPadding">
                <h2>Productomschrijving</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum curabitur in elementum, rhoncus. Neque nibh maecenas gravida et. Eu feugiat leo turpis vitae curabitur. Orci at vulputate odio in neque consectetur viverra accumsan.</p>
                <p>Aliquet iaculis sit eu sed id. Vitae risus nulla sed nulla ac, mattis non, laoreet magna. Lectus quam dapibus vestibulum mus ante tempus dictum pellentesque. Porttitor tincidunt fringilla venenatis non nunc, feugiat vulputate laoreet risus. Quam diam nulla in cursus id mi eu.</p>
            </div>
        </div>
        <div class="productBottom">
                <div class="top-four-bx">
                      <div class="innerbody-sec">
                            <div class="inner-bx">
                                <div class="inner-wht-txt-bx">
                                <h2>Superioriteit</h2>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet, consec tetur adipiscing elit.</li>
                                        <li>Lorem ipsum dolor sit amet, consec tetur adipiscing elit.</li>
                                        <li>Lorem ipsum dolor sit amet, consec tetur adipiscing elit.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="inner-bx">
                                <div class="inner-img-bx">
                                    <img src="../assets/images/pri1.png" alt="">
                                </div>
                            </div>
                            <div class="inner-bx">
                                <div class="inner-wht-txt-bx inner-brown-bg">
                                <h2>Over So Curly</h2>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet, consec tetur adipiscing elit.</li>
                                        <li>Lorem ipsum dolor sit amet, consec tetur adipiscing elit.</li>
                                        <li>Lorem ipsum dolor sit amet, consec tetur adipiscing elit.</li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class="inner-bx">
                                <div class="inner-img-bx">
                                    <img src="../assets/images/pri2.png" alt="">
                                </div>
                            </div>

                            
                        </div>
                      </div>
        </div>
      </div>
      
    </div>
     <div class="before-footer-sec">
      <div class="container">
        
        <div class="section5">
            <div class="sec5Inner">
                <div class="appoText">Klaar om aan jouw krullen reis te beginnen?</div>
                <div class="appoBtn">
                    <a data-bs-toggle="modal" data-bs-target="#appoModal" class="btnAf">AFSPRAAK MAKEN</a>
                </div>
            </div>
        </div>
        <div class="section6">
            <div class="divider">
                <img src="../assets/images/divider.svg" alt="">
            </div>
            <div class="sectionHeading">
                <h2>Recensies</h2>
            </div>
            <div class="testiHolder">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3>Mijn haar zit super!</h3>
                            <p>Heel blij dat ik bij Roos geweest ben, heerlijke spa behandeling en goeie tips gekregen!</p>
                            <h4>~Annemieke</h4>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3> Een brede glimlach!</h3>
                            <p>Wat een super fijne sfeer in de salon. Helemaal blij met het resultaat. En ook een heerlijk moment voor jezelf.</p>
                            <h4>~ Natasja</h4>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3>Roos weet echt wat ze doet!</h3>
                            <p>Na twee behandelingen is mijn haar al enorm beter geworden. Kan nu eindelijk mijn krullen laten zoals ze zijn, zonder pluis.</p>
                            <h4>~Bernadette</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alltesti text-center">
                <h3><a href="#">Bekijk meer</a></h3>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
 
  export default {
    name: 'MyComponent',
    components: { VueSlickCarousel },
  }
</script>